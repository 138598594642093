import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
    useEffect(() => {
        // document.getElementsByTagName("body")![0].style.overflow = "auto";
    }, []);

    return (
        <div className="vh-100" style={{ overflowY: "auto" }}>
            <div>
                <div style={{ minHeight: "100vh" }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
