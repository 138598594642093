import { createSlice } from '@reduxjs/toolkit';
import { IRecipientDocument } from 'src/models/transactionService/recipientDocument';

export interface IRecipientDocumentPayload {
  payload: {
    data: IRecipientDocument[];
  };
}

interface IRecipientDocumentState {
  recipientDocuments: IRecipientDocument[];
}

const initialState: IRecipientDocumentState = {
  recipientDocuments: []
};

const stateName = 'recipientDocuments';

const RecipientDocumentSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setRecipientDocuments: (state, { payload }: IRecipientDocumentPayload) => {
      state.recipientDocuments = payload.data;
    },
    appendRecipientDocument: (
      state,
      { payload }: { payload: { data: IRecipientDocument } }
    ) => {
      state.recipientDocuments.push(payload.data);
    },
    updateRecipientDocument: (
      state,
      { payload }: { payload: { data: IRecipientDocument } }
    ) => {
      const index = state.recipientDocuments.findIndex(
        (recipientDocument) => recipientDocument.id === payload.data.id
      );
      state.recipientDocuments[index] = payload.data;
    },
    removeRecipientDocument: (state, { payload }: { payload: { id: string } }) => {
      state.recipientDocuments = state.recipientDocuments.filter(
        (value) => value.id !== payload.id
      );
    },
    resetCounties: (state) => {
      state.recipientDocuments = [];
    }
  }
});

///// Export state values ////////
export const selectRecipientDocumentsState = (state: any): IRecipientDocument[] =>
  state.entities[stateName].recipientDocuments;

///// Export actions ///////
export const {
  setRecipientDocuments,
  resetCounties,
  appendRecipientDocument,
  updateRecipientDocument,
  removeRecipientDocument
} = RecipientDocumentSlice.actions;

export { stateName, RecipientDocumentSlice };
