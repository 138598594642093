export enum EStorageKeys {
    token = "token",
    refreshToken = "refreshToken",
    refreshTime = "refreshTime",
}

export enum ERole {
    SuperAdmin = "SuperAdmin",
    Admin = "Admin",
    HR = "HR",
    Finance = "Finance",
    Marketing = "Marketing",
    CustomerService = "Customer Service",
    UnAuthenticated = "UnAuthenticated",
}
