import { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "src/context/ThemeContext";
import AuthStorage from "src/storage/auth";
import { FaHome, FaInfoCircle } from "react-icons/fa";

export default function Sidebar() {
    const { iconHover, sidebarPosition, headerPosition, sidebarLayout } = useContext(ThemeContext);
    useEffect(() => {
        var btn = document.querySelector(".nav-control")!;
        var aaa = document.querySelector("#main-wrapper")!;
        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }
        btn.addEventListener("click", toggleFunc);
    }, []);
    /// Path
    const path = window.location.pathname.replace("/", "");
    /// Active menu
    const userServiceRoutes = ["", "/countries", "/states", "/users", "/policies"],
        transactionRoutes = [
            "/countries",
            "/states",
            "/currencies",
            "/currency-exchanges",
            "/transactions",
            "/transaction-types",
            "/sending-reasons",
            "/banks",
            "/recipients",
        ];
    const user = useMemo(() => {
        return AuthStorage.get()!;
    }, []);
    return (
        <div
            style={{ overflowY: "auto" }}
            className={`dlabnav ${iconHover} ${
                sidebarPosition.value === "fixed" && sidebarLayout.value === "horizontal" && headerPosition.value === "static"
                    ? "fixed"
                    : ""
            }`}>
            <div className="dlabnav-scroll">
                <div className="header-info2 d-flex align-items-center border" style={{ margin: "10px 10px 0" }}>
                    <div className="d-flex align-items-center sidebar-info" style={{ width: "100%", justifyContent: "center" }}>
                        <div>
                            <span className="font-w700 d-block mb-2">{`${user?.first_name} ${user?.last_name}`}</span>
                            <small className="text-end font-w400">{user?.role ?? "Admin"}</small>
                        </div>
                    </div>
                </div>
                <div className="mm-wrapper">
                    <ul className="metismenu">
                        <li className={`${userServiceRoutes.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow" to="#">
                                <FaHome />
                                <span className="nav-text">User Service</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${path === "user/service/countries" ? "mm-active" : ""}`}
                                        to="/user/service/countries">
                                        Countries
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${path === "user/service/policies" ? "mm-active" : ""}`} to="/user/service/policies">
                                        {" "}
                                        Policies
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${path === "user/service/states" ? "mm-active" : ""}`} to="/user/service/states">
                                        States
                                    </Link>
                                </li>
                                <li>
                                    <Link className={`${path === "user/service/users" ? "mm-active" : ""}`} to="/user/service/users">
                                        Users
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li className={`${transactionRoutes.includes(path) ? "mm-active" : ""}`}>
                            <Link className="has-arrow ai-icon" to="#">
                                <FaInfoCircle />
                                <span className="nav-text">Transaction Service</span>
                            </Link>
                            <ul>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/banks" ? "mm-active" : ""}`}
                                        to="/transaction/service/banks">
                                        Banks
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/countries" ? "mm-active" : ""}`}
                                        to="/transaction/service/countries">
                                        {" "}
                                        Countries
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/currencies" ? "mm-active" : ""}`}
                                        to="/transaction/service/currencies">
                                        {" "}
                                        Currencies
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/currency-exchanges" ? "mm-active" : ""}`}
                                        to="/transaction/service/currency-exchanges">
                                        Currency Exchange
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/recipients" ? "mm-active" : ""}`}
                                        to="/transaction/service/recipients">
                                        Recipients
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/sending-reasons" ? "mm-active" : ""}`}
                                        to="/transaction/service/sending-reasons">
                                        Sending Reasons
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/states" ? "mm-active" : ""}`}
                                        to="/transaction/service/states">
                                        States
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/transactions" ? "mm-active" : ""}`}
                                        to="/transaction/service/transactions">
                                        Transaction
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        className={`${path === "transaction/service/transaction-types" ? "mm-active" : ""}`}
                                        to="/transaction/service/transaction-types">
                                        Transaction Types
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>{" "}
                <div className="copyright">
                    <p>
                        <strong>Duruj Admin Dashboard</strong> © 2022 All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    );
}
