import { combineReducers } from '@reduxjs/toolkit';

import { stateName as authStateName, AuthSlice } from './state/auth.state';
import {
  stateName as snackbarStateName,
  SnackbarSlice
} from './state/snackbar.state';
//////////////////////////////////////////////////////////////////////////////
// user service
//////////////////////////////////////////////////////////////////////////////
import {
  stateName as countryUserStateName,
  CountrySlice as CountryUserSlice
} from './state/userService/country';
import {
  stateName as policyStateName,
  PolicySlice
} from './state/userService/policy';
import {
  stateName as stateUserStateName,
  StateSlice as StateUserSlice
} from './state/userService/state';
import {
  stateName as userStateName,
  UserSlice
} from './state/userService/user';

//////////////////////////////////////////////////////////////////////////////
// transaction service
//////////////////////////////////////////////////////////////////////////////
import {
  stateName as bankStateName,
  BankSlice
} from './state/transactionService/bank';
import {
  stateName as countryTransactionStateName,
  CountrySlice as CountryTransactionSlice
} from './state/transactionService/country';
import {
  stateName as currencyStateName,
  CurrencySlice
} from './state/transactionService/currency';
import {
  stateName as currencyExchangeStateName,
  CurrencyExchangeSlice
} from './state/transactionService/currencyExchange';
import {
  stateName as recipientStateName,
  RecipientSlice
} from './state/transactionService/recipient';
import {
  stateName as recipientDocumentsStateName,
  RecipientDocumentSlice,
} from './state/transactionService/recipientDocument';
import {
  stateName as sendingReasonStateName,
  SendingReasonSlice
} from './state/transactionService/sendingReason';
import {
  stateName as transactionStateName,
  TransactionSlice
} from './state/transactionService/transaction';
import {
  stateName as transactionTypeStateName,
  TransactionTypeSlice
} from './state/transactionService/transactionType';
import {
  stateName as stateTransactionStateName,
  StateSlice as StateTransactionSlice
} from './state/transactionService/state';
import {
  stateName as transactionDocumentStateName,
  TransactionDocumentSlice,
} from './state/transactionService/transactionDocument';
import {
  stateName as userDocumentStateName,
  UserDocumentSlice
} from './state/userService/userDocument';

export default combineReducers({
  [authStateName]: AuthSlice.reducer,
  [snackbarStateName]: SnackbarSlice.reducer,
  //////////////////////////////////////////////////////////////////////////////
  // user service
  //////////////////////////////////////////////////////////////////////////////
  [countryUserStateName]: CountryUserSlice.reducer,
  [policyStateName]: PolicySlice.reducer,
  [userStateName]: UserSlice.reducer,
  [userDocumentStateName]: UserDocumentSlice.reducer,
  //////////////////////////////////////////////////////////////////////////////
  // transaction service
  //////////////////////////////////////////////////////////////////////////////
  [countryTransactionStateName]: CountryTransactionSlice.reducer,
  [bankStateName]: BankSlice.reducer,
  [currencyStateName]: CurrencySlice.reducer,
  [currencyExchangeStateName]: CurrencyExchangeSlice.reducer,
  [recipientStateName]: RecipientSlice.reducer,
  [recipientDocumentsStateName]: RecipientDocumentSlice.reducer,
  [sendingReasonStateName]: SendingReasonSlice.reducer,
  [stateTransactionStateName]: StateTransactionSlice.reducer,
  [transactionStateName]: TransactionSlice.reducer,
  [transactionDocumentStateName]: TransactionDocumentSlice.reducer,
  [stateUserStateName]: StateUserSlice.reducer,
  [transactionTypeStateName]: TransactionTypeSlice.reducer
});
