import { ITheme } from "src/models/theme";

let direction: "ltr" = "ltr";

export const themes: ITheme[] = [
    {
        /* Default */
        typography: "Nunito",
        version: "light",
        layout: "vertical",
        headerBg: "color_1",
        navHeaderBg: "color_1",
        sidebarBg: "color_1",
        sidebarStyle: "full",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "full",
        direction: direction,
    },
    {
        /* Demo Theme 1 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navHeaderBg: "color_3",
        headerBg: "color_1",
        sidebarStyle: "full",
        sidebarBg: "color_3",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_3",
        direction: direction,
    },
    {
        /* Demo Theme 2 */
        typography: "Nunito",
        version: "light",
        layout: "vertical",
        navHeaderBg: "color_12",
        headerBg: "color_3",
        sidebarStyle: "full",
        sidebarBg: "color_12",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_3",
        direction: direction,
    },
    {
        /* Demo Theme 3 */
        typography: "poppins",
        version: "light",
        layout: "vertical",
        navHeaderBg: "color_2",
        headerBg: "color_2",
        sidebarStyle: "full",
        sidebarBg: "color_1",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_2",
        direction: direction,
    },
    {
        /* Demo Theme 4 */
        typography: "Nunito",
        version: "light",
        layout: "vertical",
        navHeaderBg: "color_1",
        headerBg: "color_1",
        sidebarStyle: "modern",
        sidebarBg: "color_9",
        sidebarPosition: "static",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction,
    },
    {
        /* Demo Theme 5 */
        typography: "Nunito",
        version: "dark",
        layout: "vertical",
        navHeaderBg: "color_3",
        headerBg: "color_1",
        sidebarStyle: "full",
        sidebarBg: "color_3",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_1",
        direction: direction,
    },
    {
        /* Demo Theme 6 */
        typography: "poppins",
        version: "light",
        layout: "horizontal",
        navHeaderBg: "color_1",
        headerBg: "color_1",
        sidebarStyle: "full",
        sidebarBg: "color_5",
        sidebarPosition: "fixed",
        headerPosition: "fixed",
        containerLayout: "wide",
        primary: "color_5",
        direction: direction,
    },
];
