import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { ERole } from "src/constants/enum";
import { TransactionServiceRouteName } from "src/constants/routeName";

const BankPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/Bank")));
const TransactionCountryPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/Country")));
const CurrencyPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/Currency")));
const CurrencyExchangePage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/CurrencyExchange")));
const RecipientPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/Recipient")));
const RecipientDetailPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/RecipientDetail")));
const SendingReasonPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/SendingReason")));
const TransactionStatePage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/State")));
const TransactionPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/Transaction")));
const TransactionDetailPage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/TransactionDetail")));
const TransactionTypePage = SuspenseLoader(lazy(() => import("src/pages/TransactionService/TransactionType")));

const TransactionServiceRoutes = (role: ERole): RouteObject[] => {
    const routes: RouteObject[] = [];
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.BANK,
            element: <BankPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.COUNTRY,
            element: <TransactionCountryPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.CURRENCY,
            element: <CurrencyPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.CURRENCY_EXCHANGE,
            element: <CurrencyExchangePage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.RECIPIENT,
            element: <RecipientPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.RECIPIENT_DETAIL,
            element: <RecipientDetailPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.SENDING_REASON,
            element: <SendingReasonPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.STATES,
            element: <TransactionStatePage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin, ERole.Finance].includes(role))
        routes.push({
            path: TransactionServiceRouteName.TRANSACTION,
            element: <TransactionPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin, ERole.Finance].includes(role))
        routes.push({
            path: TransactionServiceRouteName.TRANSACTION_DETAIL,
            element: <TransactionDetailPage />,
        });
    if ([ERole.SuperAdmin, ERole.Admin].includes(role))
        routes.push({
            path: TransactionServiceRouteName.TRANSACTION_TYPE,
            element: <TransactionTypePage />,
        });

    return routes;
};
export default TransactionServiceRoutes;
