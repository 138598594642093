import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "src/context/ThemeContext";
import { FaSun, FaMoon } from "react-icons/fa";
import Notification from "./Notification";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../images/profile/1.png";
import { Dropdown } from "react-bootstrap";
import { MdLogout } from "react-icons/md";

export default function Header() {
    const { changeBackground, background } = useContext(ThemeContext);
    const [title, setTitle] = useState<string>("");
    const path = useLocation().pathname.split("/");
    const navigate = useNavigate();

    useEffect(() => {
        const length = path.length;
        const lastIndex = length - 1;
        if (path[lastIndex]?.length >= 0) {
            if (path?.length === 5) setTitle(`${path[lastIndex - 1].replace("-", " ")} Detail`);
            else setTitle(path[lastIndex].replace("-", " "));
        } else setTitle("Dashboard");
    }, [path]);

    const handleDarkMode = () => changeBackground({ value: "dark", label: "Dark" });
    const handleLightMode = () => changeBackground({ value: "light", label: "Light" });
    const handleLogout = () => navigate("/auth/logout");

    return (
        <div className="header border-bottom">
            <div className="header-content">
                <nav className="navbar navbar-expand">
                    <div className="collapse navbar-collapse justify-content-between">
                        <div className="header-left">
                            <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                                {title}
                            </div>
                        </div>
                        <ul className="navbar-nav header-right">
                            <Notification />
                            {background?.value?.toLowerCase() === "light" ? (
                                <li className="nav-item d-flex align-items-center">
                                    <button onClick={handleDarkMode} className="btn" style={{ padding: "5px 10px", boxShadow: "none" }}>
                                        <FaMoon />
                                    </button>
                                </li>
                            ) : (
                                <li className="nav-item d-flex align-items-center">
                                    <button
                                        onClick={handleLightMode}
                                        className="btn"
                                        style={{ padding: "5px 10px", border: "none !important", boxShadow: "none" }}>
                                        <FaSun className="text-white" />
                                    </button>
                                </li>
                            )}
                            <li className="nav-item d-flex align-items-center">
                                <Dropdown className="dropdown ms-auto text-right">
                                    <Dropdown.Toggle variant="" className="btn-link i-false" data-toggle="dropdown">
                                        <img src={logo} alt="img" style={{ height: 40 }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                        <Dropdown.Item onClick={handleLogout}>
                                            <div className="text-danger d-flex justify-content-center align-items-center">
                                                <MdLogout className="me-1" /> Logout
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    );
}
