import { lazy } from "react";
import { RouteObject } from "react-router-dom";
import SuspenseLoader from "src/components/SuspenseLoader";
import { RouteName } from "src/constants/routeName";
import AuthLayout from "src/layouts/AuthLayout";

const Login = SuspenseLoader(lazy(() => import("src/pages/Auth/Login")));
const Status404 = SuspenseLoader(lazy(() => import("src/pages/Status404")));

const AuthRoute: RouteObject[] = [
    {
        path: "",
        element: <AuthLayout />,
        children: [
            {
                path: RouteName.LOGIN,
                element: <Login />,
            },
            {
                path: "*",
                element: <Status404 />,
            },
        ],
    },
];

export default AuthRoute;
