import { createSlice } from "@reduxjs/toolkit";
import { ISelectOption } from "src/models/others";
import { ICountry } from "src/models/transactionService/country";
import { ICountryTable } from "src/models/userService/country";

export interface ICountryPayload {
    payload: {
        data: ICountry[];
    };
}

interface IThirdPartyCountry {
    label: string;
    shortName: string;
    code: string;
}

interface ICountryState {
    countries: ICountry[];
    thirdPartyCountries: IThirdPartyCountry[];
    reload: number;
}

const initialState: ICountryState = {
    countries: [],
    thirdPartyCountries: [],
    reload: 0,
};

const stateName = "transaction_countries";

const CountrySlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setCountries: (state, { payload }: { payload: { data: any[] } }) => {
            state.countries = payload.data;
        },
        appendCountry: (state, { payload }: { payload: { data: ICountry } }) => {
            state.countries.push(payload.data);
        },
        updateCountry: (state, { payload }: { payload: { data: ICountry } }) => {
            const index = state.countries.findIndex((country) => country.id === payload.data.id);
            state.countries[index] = payload.data;
        },
        setThirdPartyCountries: (state, { payload }: { payload: { data: IThirdPartyCountry[] } }) => {
            state.thirdPartyCountries = payload.data;
        },
        removeCountry: (state, { payload }: { payload: { id: string } }) => {
            state.countries = state.countries.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.countries = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectCountriesForTable = (state: any): ICountryTable[] =>
    state.entities[stateName].countries.map((value: ICountry) => ({
        col1: value.name,
        col2: value.short_name,
        col3: value.code,
        col4: value.active ? "Active" : "In active",
        col5: value,
    }));
export const selectCountriesForOption = (state: any): ISelectOption[] =>
    state.entities[stateName].countries.map((value: ICountry) => ({
        value: value.id,
        label: value.name,
    }));
export const selectReloadStates = (state: any): number => state.entities[stateName].reload;
export const selectThirdPartyCountriesState = (state: any): IThirdPartyCountry[] => state.entities[stateName].thirdPartyCountries;

///// Export actions ///////
export const { reloadState, setCountries, resetCounties, setThirdPartyCountries, appendCountry, updateCountry, removeCountry } =
    CountrySlice.actions;

export { stateName, CountrySlice };
