import { createSlice } from "@reduxjs/toolkit";
import { ISelectOption } from "src/models/others";
import { IPolicy, IPolicyTable } from "src/models/userService/policy";

export interface IPolicyPayload {
    payload: {
        data: IPolicy[];
    };
}

interface IPolicyState {
    policies: IPolicy[];
    reload: number;
}

const initialState: IPolicyState = {
    policies: [],
    reload: 0,
};

const stateName = "policies";

const PolicySlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setPolicies: (state, { payload }: IPolicyPayload) => {
            state.policies = payload.data;
        },
        appendPolicy: (state, { payload }: { payload: { data: IPolicy } }) => {
            state.policies.push(payload.data);
        },
        updatePolicy: (state, { payload }: { payload: { data: IPolicy } }) => {
            const index = state.policies.findIndex((policy) => policy.id === payload.data.id);
            state.policies[index] = payload.data;
        },
        removePolicy: (state, { payload }: { payload: { id: string } }) => {
            state.policies = state.policies.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.policies = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectPoliciesState = (state: any): IPolicy[] => state.entities[stateName].policies;
export const selectPoliciesForOption = (state: any): ISelectOption[] =>
    state.entities[stateName].policies.map((value: IPolicy) => ({
        value: value.id,
        label: value.name,
    }));
export const selectPoliciesForTable = (state: any): IPolicyTable[] =>
    state.entities[stateName].policies.map((value: IPolicy) => ({
        col1: value.name,
        col2: value.penality_time,
        col3: value.description,
        col4: value.active ? "Active" : "In active",
        col5: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setPolicies, resetCounties, appendPolicy, updatePolicy, removePolicy } = PolicySlice.actions;

export { stateName, PolicySlice };
