import { ERole } from "src/constants/enum";
import { StorageKey } from "src/constants/storage";
import { IUserDetail } from "src/models/auth";

export default class AuthStorage {
    public static get(): IUserDetail | null {
        try {
            const data = localStorage.getItem(StorageKey.USER_DETAIL);
            if (data) return JSON.parse(data);
            return null;
        } catch {
            return null;
        }
    }
    public static token(): string | undefined {
        return this.get()?.token;
    }

    public static refreshToken(): string | undefined {
        return this.get()?.refreshToken;
    }
    public static set(data: IUserDetail) {
        localStorage.setItem(StorageKey.USER_DETAIL, JSON.stringify(data));
    }
    public static remove() {
        localStorage.removeItem(StorageKey.USER_DETAIL);
    }
}
