import { createContext, useEffect, useState, ReactNode } from "react";
import { ITheme, IThemeColor, IThemeValueLabel } from "src/models/theme";
import { themes } from "src/theme";

interface IThemeContext {
    [key: string]: any;
}

interface IProps {
    children: ReactNode;
}

export const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

const ThemeContextProvider = ({ children }: IProps) => {
    const [sideBarStyle, setSideBarStyle] = useState<IThemeValueLabel>({ value: "full", label: "Full" });
    const [sidebarPosition, setSidebarPosition] = useState<IThemeValueLabel>({ value: "fixed", label: "Fixed" });
    const [headerPosition, setHeaderPosition] = useState<IThemeValueLabel>({ value: "fixed", label: "Fixed" });
    const [sidebarLayout, setSidebarLayout] = useState<IThemeValueLabel>({ value: "vertical", label: "Vertical" });
    const [direction, setDirection] = useState<IThemeValueLabel>({ value: "ltr", label: "LTR" });
    const [primaryColor, setPrimaryColor] = useState<IThemeColor>("color_1");
    const [navigationHandler, setNavigationHandler] = useState<IThemeColor>("color_1");
    const [handlerColor, setHandlerColor] = useState<IThemeColor>("color_1");
    const [sidebarColor, setSidebarColor] = useState<IThemeColor>("color_1");
    const [iconHover, setIconHover] = useState<boolean | string>(false);
    const [menuToggle, setMenuToggle] = useState<boolean>(false);
    const [background, setBackground] = useState<IThemeValueLabel>({ value: "light", label: "Light" });
    const [containerPosition_, setContainerPosition_] = useState<IThemeValueLabel>({ value: "wide-boxed", label: "Wide Boxed" });
    const body = document.querySelector("body")!;
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [windowHeight, setWindowHeight] = useState<number>(0);

    // layout
    const layoutOption = [
        { value: "vertical", label: "Vertical" },
        { value: "horizontal", label: "Horizontal" },
    ];
    const sideBarOption = [
        { value: "compact", label: "Compact" },
        { value: "full", label: "Full" },
        { value: "mini", label: "Mini" },
        { value: "modern", label: "Modern" },
        { value: "overlay", label: "Overlay" },
        { value: "icon-hover", label: "Icon-hover" },
    ];
    const backgroundOption = [
        { value: "light", label: "Light" },
        { value: "dark", label: "Dark" },
    ];
    const sidebarPositions = [
        { value: "fixed", label: "Fixed" },
        { value: "static", label: "Static" },
    ];
    const headerPositions = [
        { value: "fixed", label: "Fixed" },
        { value: "static", label: "Static" },
    ];
    const containerPosition = [
        { value: "wide-boxed", label: "Wide Boxed" },
        { value: "boxed", label: "Boxed" },
        { value: "wide", label: "Wide" },
    ];
    const colors = [
        "color_1",
        "color_2",
        "color_3",
        "color_4",
        "color_5",
        "color_6",
        "color_7",
        "color_8",
        "color_9",
        "color_10",
        "color_11",
        "color_12",
        "color_13",
        "color_14",
        "color_15",
    ];
    const directionPosition = [
        { value: "ltr", label: "LTR" },
        { value: "rtl", label: "RTL" },
    ];
    const fontFamily = [
        { value: "nunito", label: "Nunito" },
        { value: "roboto", label: "Roboto" },
        { value: "poppins", label: "Poppins" },
        { value: "opensans", label: "Open Sans" },
        { value: "HelveticaNeue", label: "HelveticaNeue" },
    ];
    const changePrimaryColor = (name: IThemeColor) => {
        setPrimaryColor(name);
        body.setAttribute("data-primary", name);
    };
    const changeNavigationHandler = (name: IThemeColor) => {
        setNavigationHandler(name);
        body.setAttribute("data-nav-headerbg", name);
    };
    const changeHandlerColor = (name: IThemeColor) => {
        setHandlerColor(name);
        body.setAttribute("data-headerbg", name);
    };
    const changeSidebarColor = (name: IThemeColor) => {
        setSidebarColor(name);
        body.setAttribute("data-sibebarbg", name);
    };
    const changeSideBarPosition = (name: IThemeValueLabel) => {
        setSidebarPosition(name);
        body.setAttribute("data-sidebar-position", name.value);
    };
    const changeDirectionLayout = (name: IThemeValueLabel) => {
        setDirection(name);
        body.setAttribute("direction", name.value);
        const html = document.querySelector("html")!;
        html.setAttribute("dir", name.value);
        html.className = name.value;
    };
    const changeSideBarLayout = (name: IThemeValueLabel) => {
        if (name.value === "horizontal") {
            if (sideBarStyle.value === "overlay") {
                setSidebarLayout(name);
                body.setAttribute("data-layout", name.value);
                setSideBarStyle({ value: "full", label: "Full" });
                body.setAttribute("data-sidebar-style", "full");
            } else {
                setSidebarLayout(name);
                body.setAttribute("data-layout", name.value);
            }
        } else {
            setSidebarLayout(name);
            body.setAttribute("data-layout", name.value);
        }
    };
    const changeSideBarStyle = (name: IThemeValueLabel) => {
        if (sidebarLayout.value === "horizontal") {
            if (name.value === "overlay") {
                alert("Sorry! Overlay is not possible in Horizontal layout.");
            } else {
                setSideBarStyle(name);
                setIconHover(name.value === "icon-hover" ? "_i-hover" : "");
                body.setAttribute("data-sidebar-style", name.value);
            }
        } else {
            setSideBarStyle(name);
            setIconHover(name.value === "icon-hover" ? "_i-hover" : "");
            body.setAttribute("data-sidebar-style", name.value);
        }
    };

    const changeHeaderPosition = (name: IThemeValueLabel) => {
        setHeaderPosition(name);
        body.setAttribute("data-header-position", name.value);
    };

    const openMenuToggle = () => {
        sideBarStyle.value === "overly" ? setMenuToggle(true) : setMenuToggle(false);
    };

    const changeBackground = (name: IThemeValueLabel) => {
        body.setAttribute("data-theme-version", name.value);
        setBackground(name);
    };

    const changeContainerPosition = (name: IThemeValueLabel) => {
        setContainerPosition_(name);
        body.setAttribute("data-container", name.value);
        name.value === "boxed" && changeSideBarStyle({ value: "overlay", label: "Overlay" });
    };

    const setDemoTheme = (theme: number, direction: "ltr") => {
        const setAttr: IThemeValueLabel = {} as IThemeValueLabel;

        const themeSettings: ITheme = themes[theme];

        body.setAttribute("data-typography", themeSettings.typography);

        setAttr.value = themeSettings.version;
        changeBackground(setAttr);

        setAttr.value = themeSettings.layout;
        changeSideBarLayout(setAttr);

        //setAttr.value = themeSettings.primary;
        changePrimaryColor(themeSettings.primary!);

        //setAttr.value = themeSettings.navHeaderBg;
        changeNavigationHandler(themeSettings.navHeaderBg);

        //setAttr.value = themeSettings.headerBg;
        changeHandlerColor(themeSettings.headerBg);

        setAttr.value = themeSettings.sidebarStyle;
        changeSideBarStyle(setAttr);

        //setAttr.value = themeSettings.sidebarBg;
        changeSidebarColor(themeSettings.sidebarBg);

        setAttr.value = themeSettings.sidebarPosition;
        changeSideBarPosition(setAttr);

        setAttr.value = themeSettings.headerPosition;
        changeHeaderPosition(setAttr);

        setAttr.value = themeSettings.containerLayout;
        changeContainerPosition(setAttr);

        //setAttr.value = themeSettings.direction;
        setAttr.value = direction;
        changeDirectionLayout(setAttr);
    };

    useEffect(() => {
        const body = document.querySelector("body")!;
        body.setAttribute("data-typography", "Nunito");
        body.setAttribute("data-theme-version", "light");
        body.setAttribute("data-layout", "vertical");
        body.setAttribute("data-primary", "color_1");
        body.setAttribute("data-nav-headerbg", "color_1");
        body.setAttribute("data-headerbg", "color_1");
        body.setAttribute("data-sidebar-style", "overlay");
        body.setAttribute("data-sibebarbg", "color_1");
        body.setAttribute("data-primary", "color_1");
        body.setAttribute("data-sidebar-position", "fixed");
        body.setAttribute("data-header-position", "fixed");
        body.setAttribute("data-container", "wide");
        body.setAttribute("direction", "ltr");
        let resizeWindow = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
            window.innerWidth >= 768 && window.innerWidth < 1024
                ? body.setAttribute("data-sidebar-style", "mini")
                : window.innerWidth <= 768
                ? body.setAttribute("data-sidebar-style", "overlay")
                : body.setAttribute("data-sidebar-style", "full");
        };
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    return (
        <ThemeContext.Provider
            value={{
                body,
                sideBarOption,
                layoutOption,
                backgroundOption,
                sidebarPosition,
                headerPositions,
                containerPosition,
                directionPosition,
                fontFamily,
                primaryColor,
                navigationHandler,
                windowWidth,
                windowHeight,
                changePrimaryColor,
                changeNavigationHandler,
                changeSideBarStyle,
                sideBarStyle,
                changeSideBarPosition,
                sidebarPositions,
                changeHeaderPosition,
                headerPosition,
                changeSideBarLayout,
                sidebarLayout,
                changeDirectionLayout,
                changeContainerPosition,
                direction,
                colors,
                handlerColor,
                changeHandlerColor,
                changeSidebarColor,
                sidebarColor,
                iconHover,
                menuToggle,
                openMenuToggle,
                changeBackground,
                background,
                containerPosition_,
                setDemoTheme,
            }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
