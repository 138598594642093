import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RouteName } from "src/constants/routeName";
import AuthStorage from "src/storage/auth";
import { logOut, setAuth } from "src/store/state/auth.state";

export default function useAuth() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleLogout = () => {
        AuthStorage.remove();
        dispatch(logOut());
        navigate(RouteName.LOGIN, { replace: true, state: { from: location.state?.from } });
    };

    const handleLogoutWithRedirect = (path: string) => {
        AuthStorage.remove();
        navigate(RouteName.LOGIN, { replace: true, state: { from: path } });
    };
    const runLogoutTimer = (timer: number) => {
        setTimeout(() => {
            handleLogoutWithRedirect(location.pathname);
        }, timer);
    };

    const checkAutoLogin = (): boolean => {
        let tokenDetails = AuthStorage.get();
        if (!tokenDetails?.token) {
            handleLogout();
            return false;
        }

        const expireDate = new Date(tokenDetails.expireDate);
        const todaysDate = new Date();

        if (todaysDate.getTime() > expireDate.getTime()) {
            handleLogout();
            return false;
        }
        dispatch(setAuth({ data: { refreshToken: tokenDetails.refreshToken, token: tokenDetails.token, user: tokenDetails } }));
        AuthStorage.set(tokenDetails);

        const timer = expireDate.getTime() - todaysDate.getTime();
        runLogoutTimer(timer);
        return true;
    };

    return {
        handleLogout,
        checkAutoLogin,
    };
}
