import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useRoutes } from "react-router-dom";
import Fallback from "src/components/Fallback";
import useAuth from "src/hooks/utilities/useAuth";
import Route from "src/routes";
import AuthRoute from "src/routes/auth";
import { selectAuthRole } from "./store/state/auth.state";

export default function App() {
    const { checkAutoLogin } = useAuth();
    const location = useLocation();
    const [routes, setRoutes] = useState<JSX.Element>(<Fallback />);
    const unAuthenticatedRoutes = useRoutes(AuthRoute)!;
    const authenticatedRoutes = useRoutes(Route())!;
    const role = useSelector(selectAuthRole);

    useEffect(() => {
        const isAuthenticated = checkAutoLogin();
        if (isAuthenticated) setRoutes(authenticatedRoutes);
        else setRoutes(unAuthenticatedRoutes);
        // eslint-disable-next-line
    }, [location.pathname, role]);

    return <>{routes}</>;
}
