import { createSlice } from "@reduxjs/toolkit";
import { ISendingReason, ISendingReasonTable } from "src/models/transactionService/sendingReason";

export interface ISendingReasonPayload {
    payload: {
        data: ISendingReason[];
    };
}

interface ISendingReasonState {
    sendingReasons: ISendingReason[];
    reload: number;
}

const initialState: ISendingReasonState = {
    sendingReasons: [],
    reload: 0,
};

const stateName = "sendingReasons";

const SendingReasonSlice = createSlice({
    name: stateName,
    initialState,
    reducers: {
        setSendingReasons: (state, { payload }: ISendingReasonPayload) => {
            state.sendingReasons = payload.data;
        },
        appendSendingReason: (state, { payload }: { payload: { data: ISendingReason } }) => {
            state.sendingReasons.push(payload.data);
        },
        updateSendingReason: (state, { payload }: { payload: { data: ISendingReason } }) => {
            const index = state.sendingReasons.findIndex((sendingReason) => sendingReason.id === payload.data.id);
            state.sendingReasons[index] = payload.data;
        },
        removeSendingReason: (state, { payload }: { payload: { id: string } }) => {
            state.sendingReasons = state.sendingReasons.filter((value) => value.id !== payload.id);
        },
        resetCounties: (state) => {
            state.sendingReasons = [];
        },
        reloadState: (state) => {
            state.reload = state.reload + 1;
        },
    },
});

///// Export state values ////////
export const selectSendingReasonsState = (state: any): ISendingReason[] => state.entities[stateName].sendingReasons;
export const selectSendingReasonsForTable = (state: any): ISendingReasonTable[] =>
    state.entities[stateName].sendingReasons.map((value: ISendingReason) => ({
        col1: value.title,
        col2: value.description,
        col3: value,
    }));
export const selectReloadState = (state: any): number => state.entities[stateName].reload;

///// Export actions ///////
export const { reloadState, setSendingReasons, resetCounties, appendSendingReason, updateSendingReason, removeSendingReason } =
    SendingReasonSlice.actions;

export { stateName, SendingReasonSlice };
